import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Sound from "../../../components/Sound";
import usePrevious from "../../../hooks/usePrevious";
import { useAppDispatch } from "../../../state/hooks";
import { resetSwipeMessage, SwipeType } from "../../../state/slices/swipe";

import click from "../../../assets/sounds/klick.mp3";

import box_2 from "../../../assets/img/03_collection/collection_bg-branch.svg";
import box_1 from "../../../assets/img/03_collection/collection_bg-cooper.svg";
import box_0 from "../../../assets/img/03_collection/collection_bg-empty.svg";
import box_3 from "../../../assets/img/03_collection/collection_bg-poppy.svg";
import box_scan from "../../../assets/img/03_collection/collection_bg-scan.svg";

import troll_2 from "../../../assets/img/03_collection/collection_troll-branch.png";
import troll_1 from "../../../assets/img/03_collection/collection_troll-cooper.png";
import troll_3 from "../../../assets/img/03_collection/collection_troll-poppy.png";

import card_2 from "../../../assets/img/03_collection/collection_card-branch.png";
import card_1 from "../../../assets/img/03_collection/collection_card-cooper.png";
import card_3 from "../../../assets/img/03_collection/collection_card-poppy.png";

type Props = {
  id: number;
  active: boolean;
};

const assets = [
  { id: 1, box: box_1, troll: troll_1, card: card_1, name: "Cooper" },
  { id: 2, box: box_2, troll: troll_2, card: card_2, name: "Branch" },
  { id: 3, box: box_3, troll: troll_3, card: card_3, name: "Poppy" },
];

const variants = { hidden: { opacity: 0 }, visible: { opacity: 1 } };

function troll(id: number) {
  return assets[assets.findIndex((item) => item.id === id)];
}

const Trollbox = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clickSound = useRef(new Sound(click));

  const grayTrollControls = useAnimation();
  const colorTrollControls = useAnimation();
  const cardControls = useAnimation();
  const nameControls = useAnimation();

  const prevActive = usePrevious(props.active);

  useEffect(() => {
    if (prevActive === false && props.active === true) {
      cardControls.start({ opacity: [0, 1], scale: [0.5, 1.2, 1], transition: { delay: 0.5 } });
      grayTrollControls.start({ opacity: 0, scale: [1, 1.1, 1] });
      colorTrollControls.start({ opacity: 1, scale: [1, 1.1, 1] });
      nameControls.start({ opacity: [0, 1], scale: [1, 1.1, 1], transition: { delay: 0.2 } });
    }
  });

  // troll
  if ([1, 2, 3].includes(props.id))
    return (
      <div className="h-full px-5">
        <motion.div
          className={clsx("w-full text-center bg-bottom bg-no-repeat bg-contain h-[60vh] flex justify-between flex-col relative pt-6")}
          style={{ backgroundImage: props.active ? `url(${troll(props.id).box})` : `url(${box_0})` }}
        >
          <div className="relative w-full h-full mx-auto">
            <motion.img
              src={troll(props.id).troll}
              alt={troll(props.id).name}
              className={clsx("mx-auto absolute bottom-0 origin-bottom inset-x-0 h-[95%]", !props.active && "grayscale")}
              variants={variants}
              initial={props.active ? "hidden" : "visible"}
              animate={grayTrollControls}
            />
            <motion.img
              src={troll(props.id).troll}
              alt={troll(props.id).name}
              className={clsx("mx-auto absolute bottom-0 origin-bottom inset-x-0 h-[95%]")}
              variants={variants}
              initial={props.active ? "visible" : "hidden"}
              animate={colorTrollControls}
            />
          </div>

          <motion.p
            className="pt-4 pb-6 text-5xl uppercase font-crispynut"
            variants={variants}
            initial={props.active ? "visible" : "hidden"}
            animate={nameControls}
          >
            {troll(props.id).name}
          </motion.p>

          <motion.img
            src={troll(props.id).card}
            alt="Collectible Card"
            className="absolute inset-y-0 h-40 -right-12 top-[45%]"
            variants={variants}
            initial={props.active ? "visible" : "hidden"}
            animate={cardControls}
          />
        </motion.div>
      </div>
    );

  // button
  return (
    <div className="h-full px-2">
      <button
        onClick={() => {
          clickSound.current.play();

          dispatch(resetSwipeMessage(SwipeType.COLLECTION));
          navigate("/addtroll", { replace: true, state: {} });
        }}
        className="flex w-full active:scale-95"
      >
        <div
          className={clsx("w-full text-center bg-bottom bg-no-repeat bg-contain h-[60vh] flex")}
          style={{ backgroundImage: `url(${box_scan})` }}
        >
          <p className="w-full mt-auto mb-24 text-3xl font-crispynut">
            Scan a<br />
            new troll
          </p>
        </div>
      </button>
    </div>
  );
};

export default Trollbox;
