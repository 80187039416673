import clsx from "clsx";
import { useRef } from "react";

import click from "../../../assets/sounds/klick.mp3";
import Sound from "../../Sound";

// types
type Props = {
  title: string;
  onClick: any;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  inverted?: boolean;
};

// component
const Button = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      type="button"
      className={clsx(
        "inline-flex items-center px-3 py-2 rounded-full justify-center",
        "select-none active:scale-95",
        { "cursor-not-allowed": props.disabled },
        { "": !props.disabled },
        // primary, enabled
        {
          "text-white border border-jakala-pink bg-jakala-pink": props.primary && !props.disabled && !props.inverted,
        },
        // primary, disabled
        {
          "text-white/50 border-jakala-pink/50 bg-jakala-pink/50": props.primary && props.disabled && !props.inverted,
        },
        // primary, disabled, inverted colors
        {
          "text-gray-900/50 border border-gray-900/50": props.primary && props.disabled && props.inverted,
        },
        // secondary, enabled
        {
          "text-white bg-white/30": !props.primary && !props.disabled && !props.inverted,
        },

        // secondary, disabled
        {
          "text-white/30 bg-white/10": !props.primary && props.disabled,
        },

        props.className
      )}
      onClick={() => {
        clickSound.current.play();
        props.onClick();
      }}
      disabled={props.disabled}
    >
      <div className="w-full text-lg font-medium">{props.title}</div>
    </button>
  );
};

Button.defaultProps = {
  primary: true,
};

export default Button;
