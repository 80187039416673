import clsx from "clsx";

type Props = {
  isSet: boolean;
  setIsSet: any;
};

const TouchToggle = (props: Props) => {
  return (
    <button
      type="button"
      aria-pressed={props.isSet}
      onClick={() => {
        props.setIsSet(!props.isSet);
      }}
      className={clsx(
        "relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 rounded-full cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        "border-jakala-pink",
        props.isSet ? "bg-jakala-pink" : "",
        { "bg-white": !props.isSet }
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={clsx(
          "inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 rounded-full ring-0 border border-white",
          { "translate-x-5 bg-white": props.isSet },
          { "translate-x-0": !props.isSet },
          !props.isSet ? "bg-jakala-pink" : ""
        )}
      ></span>
    </button>
  );
};

export default TouchToggle;
