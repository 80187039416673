import { useState } from "react";
import Modal from "../../../components/layout/Modal";
import Button from "../../../components/UI/buttons/Button";
import { useAppDispatch } from "../../../state/hooks";
import { setAppIsUnlocked } from "../../../state/slices/app";

import logo from "../../../assets/img/00_general/Logo_Jakala_1_11x.png";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
  setDependendModalIsOpen: any;
};

const PasswortRequestModal = (props: Props) => {
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");

  const handleOnClick = () => {
    // FIXME remove empty string
    // if (password === "JakalaDemo2022" || password === "") {
    if (password === "JakalaDemo2022") {
      props.setIsOpen(false);
      props.setDependendModalIsOpen(true);
      dispatch(setAppIsUnlocked());
    } else {
      alert("Incorrect password. Try again.");
    }
  };

  return (
    <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="">
      <div className="flex flex-col justify-between p-6 text-center h-[50vh] w-[70vw]">
        <img src={logo} className="h-10 mx-auto" alt="Logo Jakala" />

        <div>
          <span className="text-gray-500">Password required:</span>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 mt-4 text-center border border-gray-300 rounded-full font-extralight"
          />
        </div>

        <div className="px-4 mt-10">
          <Button title="Continue" onClick={handleOnClick} className="w-full" />
        </div>
      </div>
    </Modal>
  );
};

export default PasswortRequestModal;
