import clsx from "clsx";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import VideoJS from "../../components/UI/VideoJS";
import useTimeout from "../../hooks/useTimeout";
import { useAppDispatch } from "../../state/hooks";
import { resetSwipeMessage, SwipeType } from "../../state/slices/swipe";

import bg from "../../assets/img/00_general/bg_pink.jpg";
import power from "../../assets/img/04_game/game_star-power.png";

import game1 from "../../assets/img/04_game/game1.mp4";
import game1_placeholder from "../../assets/img/04_game/game1_placeholder.jpg";
import game2 from "../../assets/img/04_game/game2.mp4";
import game2_placeholder from "../../assets/img/04_game/game2_placeholder.jpg";

type Props = {
  origin: string;
  withSound: boolean;
};

const options_game1 = {
  poster: game1_placeholder,
  sources: [{ src: game1 }],
  controls: false,
  responsive: true,
};
const options_game2 = {
  poster: game2_placeholder,
  sources: [{ src: game2 }],
  controls: false,
  responsive: true,
};

const Game = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const playerRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  useTimeout(() => {
    // TODO remove this, if we decide to only use the withSound version
    // activate powerup button after 9sec, if there's no startButton to start the timer
    if (!props.withSound && props.origin === "/levels") {
      setButtonIsEnabled(true);
    }
  }, 9000);

  const part1 = (
    <>
      {props.withSound ? (
        <>
          <VideoJS
            options={options_game1}
            onReady={handlePlayerReady}
            playsInline
            className="flex justify-center w-screen h-full overflow-hidden"
          />
          <motion.div
            className="absolute flex w-screen h-full p-8 bg-center bg-cover"
            style={{ backgroundImage: `url(${bg})` }}
            animate={isPlaying && { opacity: [1, 0], transition: { duration: 0.5 } }}
          >
            <button
              onClick={() => {
                setIsPlaying(true);
                playerRef.current.play();
                setTimeout(() => {
                  setButtonIsEnabled(true);
                }, 9000);
              }}
              disabled={isPlaying}
              className="w-screen active:scale-95"
            >
              <motion.img
                src={power}
                className="mx-auto select-none h-36 rounded-2xl"
                alt="powerup"
                animate={{ opacity: [0, 1], scale: [0, 1], transition: { duration: 0.5 } }}
              />
              <p className="mt-8 text-4xl uppercase font-crispynut">Start</p>
            </button>
          </motion.div>
        </>
      ) : (
        <VideoJS
          options={options_game1}
          onReady={handlePlayerReady}
          playsInline
          muted
          autoPlay
          className="flex justify-center w-screen h-full overflow-hidden"
        />
      )}
      {buttonIsEnabled && (
        <button
          onClick={() => {
            dispatch(resetSwipeMessage(SwipeType.GAME));
            navigate(props.withSound ? "/powerup1" : "/powerup2", { replace: true });
          }}
          className="absolute top-0 left-0 w-screen h-[20vh]"
        ></button>
      )}
    </>
  );

  const part2 = (
    <>
      {props.withSound ? (
        <>
          <VideoJS
            options={options_game2}
            onReady={handlePlayerReady}
            playsInline
            className="flex justify-center w-screen h-full overflow-hidden"
          />
          <motion.div
            className="absolute flex w-screen h-full p-8 bg-center bg-cover"
            style={{ backgroundImage: `url(${bg})` }}
            animate={isPlaying && { opacity: [1, 0], transition: { duration: 0.5 } }}
          >
            <button
              onClick={() => {
                setIsPlaying(true);
                playerRef.current.play();
              }}
              disabled={isPlaying}
              className="w-screen active:scale-95"
            >
              <motion.img
                src={power}
                className="mx-auto select-none h-36 rounded-2xl"
                alt="powerup"
                animate={{ rotate: 360, scale: [0, 1], transition: { duration: 0.5 } }}
              />
              <p className="mt-8 text-4xl uppercase font-crispynut">Star Power</p>
            </button>
          </motion.div>
        </>
      ) : (
        <>
          <VideoJS
            options={options_game2}
            onReady={handlePlayerReady}
            playsInline
            muted
            className="flex justify-center w-screen h-full overflow-hidden"
          />
          <motion.div
            className="absolute flex w-screen h-full p-8 bg-center bg-cover"
            style={{ backgroundImage: `url(${bg})` }}
            animate={isPlaying && { opacity: [1, 0], transition: { duration: 0.5 } }}
          >
            <button
              onClick={() => {
                setIsPlaying(true);
                playerRef.current.play();
              }}
              disabled={isPlaying}
              className="w-screen active:scale-95"
            >
              <motion.img
                src={power}
                className="mx-auto select-none h-36 rounded-2xl"
                alt="powerup"
                animate={{ rotate: 360, scale: [0, 1], transition: { duration: 0.5 } }}
              />
              <p className="mt-8 text-4xl uppercase font-crispynut">Star Power</p>
            </button>
          </motion.div>
        </>
      )}
    </>
  );

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx("flex flex-col items-center h-full text-white bg-cover bg-center text-center justify-between w-screen")}
        style={{ backgroundImage: `url(${bg})` }}
      >
        {props.origin === "/levels" ? part1 : part2}
        <div className="absolute top-0 z-20 flex items-center justify-between w-screen">
          <CloseButton
            onClick={() => {
              navigate("/levels");
            }}
          />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Game;
