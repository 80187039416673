import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import MotionDiv from "../../components/motion/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { activateTroll } from "../../state/slices/collection";
import { RootState } from "../../state/store";
import Trollbox from "./Trollbox";

import bg from "../../assets/img/00_general/bg_color.jpg";
import logo from "../../assets/img/01_start/start_trolls-logo.png";

const variants = {
  active: { scale: 1, y: "-3%", transition: { easing: "easeIn", duration: 0.4 } },
  inactive: { scale: 0.8, y: 0, transition: { easing: "easeIn", duration: 0.4 } },
};

const Collection = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  let location = useLocation();
  const { newTroll } = (location.state as any) || {};

  const trollIsActive = useAppSelector((state: RootState) => state.collection.trollIsActive);

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef<any>();

  const trolls = [
    { code: "code37", name: "Cooper" },
    { code: "code31", name: "Branch" },
    { code: "code14", name: "Poppy" },
  ];

  useEffect(() => {
    if (!newTroll) return;

    const id = trolls.findIndex((code) => code.code === newTroll);
    console.warn(newTroll, id);
    setTimeout(() => {
      sliderRef.current.slickGoTo(id);
    }, 200);
    setTimeout(() => {
      dispatch(activateTroll(id));
    }, 400);
  }, []);

  return (
    <MotionDiv className="h-full">
      <div
        className="flex flex-col items-center justify-between w-full h-full pt-6 pb-6 text-white bg-center bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute top-0 z-20 flex items-center justify-between w-screen">
          <CloseButton
            onClick={() => {
              navigate(`/`, { state: {} });
            }}
          />
        </div>

        <h2 className="text-4xl font-crispynut">
          <img src={logo} alt="Logo Trolls" className="h-24 mx-auto" />
          The collection
        </h2>

        <div className="flex">
          <div className="mt-auto">
            <Slider
              dots={false}
              arrows={false}
              centerMode={true}
              className="w-screen"
              ref={sliderRef}
              afterChange={(index) => setCurrentSlide(index)}
            >
              <motion.div
                variants={variants}
                initial="inactive"
                animate={currentSlide === 0 ? "active" : "inactive"}
                className="origin-bottom"
              >
                <Trollbox id={1} active={trollIsActive[0]} />
              </motion.div>
              <motion.div
                variants={variants}
                initial="inactive"
                animate={currentSlide === 1 ? "active" : "inactive"}
                className="origin-bottom"
              >
                <Trollbox id={2} active={trollIsActive[1]} />
              </motion.div>
              <motion.div
                variants={variants}
                initial="inactive"
                animate={currentSlide === 2 ? "active" : "inactive"}
                className="origin-bottom"
              >
                <Trollbox id={3} active={trollIsActive[2]} />
              </motion.div>
              <motion.div
                variants={variants}
                initial="inactive"
                animate={currentSlide === 3 ? "active" : "inactive"}
                className="origin-bottom"
              >
                <Trollbox id={0} active={false} />
              </motion.div>
            </Slider>
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Collection;
