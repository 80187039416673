import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import CloseButton from "../../components/UI/buttons/CloseButton";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";

import bg from "../../assets/img/00_general/bg_color.jpg";

type Props = {
  origin: string;
  isGameWithSound?: boolean;
};

const Swipe = (props: Props) => {
  let navigate = useNavigate();

  return (
    <MotionDiv className="h-full bg-center bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <SwipeField origin={props.origin} isGameWithSound={props.isGameWithSound} />
      <ProgressBar />
      <NetworkIndicator />
      <SwipeMessage />
      <div className="absolute top-0 left-0 z-40">
        <CloseButton onClick={() => navigate(props.origin, { replace: true })} />
      </div>
    </MotionDiv>
  );
};

export default Swipe;
