import { motion } from "framer-motion";
import { ReactNode, useRef, useState } from "react";
import Sound from "../../Sound";

import selection from "../../../assets/img/01_start/start_layer-selection.png";

import click from "../../../assets/sounds/klick.mp3";

type Props = {
  onClick: any;
  children: ReactNode;
};

const SelectableButton = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  const [isActive, setIsActive] = useState(false);

  return (
    <button
      onClick={() => {
        clickSound.current.play();
        setIsActive(true);
        setTimeout(() => {
          props.onClick();
        }, 400);
      }}
      className="relative w-screen grid grid-cols-[1fr,auto,1fr] select-none"
    >
      <div className="grid w-screen grid-cols-[1fr,auto,1fr] py-1">
        <motion.div
          id="selectionBackground"
          className="z-0 col-span-2 col-start-1 row-start-1 bg-right bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${selection})` }}
          initial={{ x: "-100%" }}
          transition={{ duration: 0.3 }}
          variants={{
            inactive: { x: "-100%" },
            active: { x: 0 },
          }}
          animate={isActive ? "active" : "inactive"}
        ></motion.div>

        <div className="z-10 col-start-2 row-start-1 px-10 pt-4 pb-2 active:scale-95">{props.children}</div>
      </div>
    </button>
  );
};

export default SelectableButton;
