import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import SelectableButton from "../../components/UI/buttons/SelectableButton";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { setInvocationIsValid } from "../../state/slices/app";
import { RootState } from "../../state/store";
import LicenseModal from "./LicenseModal";
import PasswortRequestModal from "./PasswordRequestModal";

import bg from "../../assets/img/00_general/bg_blue.jpg";
import game from "../../assets/img/01_start/start_game.png";
import logo_prismade from "../../assets/img/01_start/start_logo.svg";
import meet from "../../assets/img/01_start/start_meet.png";
import logo_trolls from "../../assets/img/01_start/start_trolls-logo.png";

const Start = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();

  const appIsUnlocked = useAppSelector((state: RootState) => state.app.appIsUnlocked);

  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [licenseModalIsOpen, setLicenseModalIsOpen] = useState(false);

  useEffect(() => {
    dispatch(setInvocationIsValid());
    if (appIsUnlocked) {
      setPasswordModalIsOpen(false);
    } else {
      setPasswordModalIsOpen(true);
    }
  }, []);

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx("flex flex-col items-center  h-full pt-4 pb-4 text-white px-8 bg-cover bg-center text-center justify-between")}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img src={logo_trolls} alt="Trolls Logo" className="h-28" />

        <div>
          <SelectableButton onClick={() => navigate("/collection")}>
            <>
              <img src={meet} className="mx-auto select-none h-36 rounded-2xl" alt="" />
              <p className="mt-3 uppercase select-none font-crispynut">Meet the trolls</p>
            </>
          </SelectableButton>
          <SelectableButton onClick={() => navigate("/levels")}>
            <>
              <img src={game} className="mx-auto select-none h-36 rounded-2xl" alt="" />
              <p className="mt-3 uppercase select-none font-crispynut">Game</p>
            </>
          </SelectableButton>
        </div>

        <img src={logo_prismade} alt="Prismade Logo" className="h-5 mt-4" />
      </div>

      <PasswortRequestModal
        isOpen={passwordModalIsOpen}
        setIsOpen={setPasswordModalIsOpen}
        setDependendModalIsOpen={setLicenseModalIsOpen}
      />
      <LicenseModal isOpen={licenseModalIsOpen} setIsOpen={setLicenseModalIsOpen} />
    </MotionDiv>
  );
};

export default Start;
