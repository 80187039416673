import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import { BrightButton } from "../../components/UI/buttons/BrightButton";

import bg from "../../assets/img/00_general/bg_pink.jpg";
import troll_1 from "../../assets/img/03_collection/collection_troll-poppy.png";
import troll_2 from "../../assets/img/03_collection/collection_troll-branch.png";

type Props = {
  origin: string;
};

const Failure = (props: Props) => {
  const navigate = useNavigate();

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx("flex flex-col items-center h-full pt-4 pb-4 text-white px-8 bg-cover bg-center text-center justify-center w-full")}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="text-3xl font-crispynut">That did not work!</div>
        <div className="w-full mt-8">
          <BrightButton title="Try Again" onClick={() => navigate(props.origin, { replace: true })} />
        </div>
        <img src={troll_1} alt="" className="absolute bottom-0 left-0 translate-x-[-25%] translate-y-[43%] grayscale" />
        <img src={troll_2} alt="" className="absolute bottom-0 right-0 translate-x-[25%] translate-y-[50%] grayscale" />
      </div>
    </MotionDiv>
  );
};

export default Failure;
