import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import { BrightButton } from "../../components/UI/buttons/BrightButton";
import CloseButton from "../../components/UI/buttons/CloseButton";

import logo_trolls from "../../assets/img/01_start/start_trolls-logo.png";

import troll_2 from "../../assets/img/04_game/level_branch.png";
import troll_4 from "../../assets/img/04_game/level_king-trollex.png";
import troll_3 from "../../assets/img/04_game/level_poppy.png";
import troll_1 from "../../assets/img/04_game/level_tiny-diamond.png";

const Levels = () => {
  const navigate = useNavigate();

  return (
    <MotionDiv className="h-full">
      <div
        className={clsx(
          "flex flex-col items-center h-full pt-4 pb-4 text-white px-8 bg-cover bg-center text-center justify-between w-full"
        )}
      >
        <div className="absolute top-0 z-20 flex items-center justify-between w-screen">
          <CloseButton
            onClick={() => {
              navigate(`/`);
            }}
          />
        </div>

        <img src={logo_trolls} alt="Trolls Logo" className="h-32" />

        <div className="w-full space-y-8">
          <div className="relative">
            <BrightButton onClick={() => navigate("/game1")} title="level 1" />
            <img src={troll_1} alt="" className="absolute h-48 pointer-events-none select-none -top-20 -right-14 touch-none" />
          </div>
          <div className="relative">
            <BrightButton onClick={() => navigate("/game1")} title="level 2" />
            <img src={troll_2} alt="" className="absolute h-48 pointer-events-none select-none -top-14 -left-16 touch-none" />
          </div>
          <div className="relative">
            <BrightButton onClick={() => navigate("/game2")} title="level 3" />
            <img src={troll_3} alt="" className="absolute h-48 pointer-events-none select-none -top-20 -right-16 touch-none" />
          </div>
          <div className="relative">
            <BrightButton onClick={() => navigate("/game2")} title="level 4" />
            <img src={troll_4} alt="" className="absolute h-48 pointer-events-none select-none -top-16 -left-14 touch-none" />
          </div>
        </div>

        <div></div>
      </div>
    </MotionDiv>
  );
};

export default Levels;
