import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CollectionState = {
  trollIsActive: boolean[];
};

const initialState: CollectionState = {
  trollIsActive: [false, false, false],
};

export const collection = createSlice({
  name: "collection",
  initialState,
  reducers: {
    activateTroll: (state, action: PayloadAction<number>) => {
      state.trollIsActive[action.payload] = true;
    },
  },
});

export const collectionReducer = collection.reducer;
export const { activateTroll } = collection.actions;
