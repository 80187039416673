import { useState } from "react";
import Modal from "../../../components/layout/Modal";
import Button from "../../../components/UI/buttons/Button";

import logo from "../../../assets/img/00_general/Logo_Jakala_1_11x.png";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const LicenseModal = (props: Props) => {
  const [password, setPassword] = useState("");

  const handleOnClick = () => {
    props.setIsOpen(false);
  };

  return (
    <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="">
      <div className="flex flex-col justify-between p-6 text-center h-[50vh] w-[70vw]">
        <img src={logo} className="h-10 mx-auto" alt="Logo Jakala" />

        <div className="text-gray-500">
          <p>The demo you are currently playing is under licensor approval.</p>
          <p className="pt-4">Content and design can partially or totally change in the future.</p>
        </div>

        <div className="px-4 mt-8">
          <Button title="Accept" onClick={handleOnClick} className="w-full" />
        </div>
      </div>
    </Modal>
  );
};

export default LicenseModal;
