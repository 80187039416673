import Button from "../../../../components/UI/buttons/Button";
import Modal from "../../../../components/layout/Modal";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

const ShouldAddToHomeScreenModal = (props: Props) => {
  return (
    <Modal title="Display too small" isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <p className="mt-4 whitespace-pre-wrap">
        This smartphone is too small.
        <br />
        <br />
        Some information may not be visible.
        <br />
        <br />
        We recommend adding it to your home screen or trying it on a larger device.
      </p>
      <div className="mt-6">
        <Button title="Continue anyway" onClick={() => props.setIsOpen(false)} inverted={true} />
      </div>
    </Modal>
  );
};

export default ShouldAddToHomeScreenModal;
