import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import WithInvocationCheck from "./components/layout/WithInvocationCheck";
import WithOrientationNotice from "./components/layout/WithOrientationNotice";
import WithSessionExpiredWarning from "./components/layout/WithSessionExpiredWarning";

import Collection from "./pages/Collections";
import Failure from "./pages/Failure";
import Levels from "./pages/Levels";
import Start from "./pages/Start";
import Swipe from "./pages/Swipe";

import bg from "./assets/img/00_general/bg_blue.jpg";
import Game from "./pages/Game";

const RouterComponent = () => {
  const location = useLocation();

  return (
    <div className="w-full h-full bg-center bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <WithSessionExpiredWarning />
      <AnimatePresence exitBeforeEnter>
        <WithInvocationCheck path={location.pathname}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Start />} />

            <Route path="/collection" element={<Collection />} />
            <Route
              path="/addtroll"
              element={
                <WithOrientationNotice>
                  <Swipe origin="/collection" />
                </WithOrientationNotice>
              }
            />
            <Route path="/notroll" element={<Failure origin="/collection" />} />

            <Route path="/levels" element={<Levels />} />
            <Route path="/game1" element={<Game origin="/levels" withSound={true} />} />
            <Route path="/game2" element={<Game origin="/levels" withSound={false} />} />
            <Route
              path="/powerup1"
              element={
                <WithOrientationNotice>
                  <Swipe origin="/levels" isGameWithSound={true} />
                </WithOrientationNotice>
              }
            />
            <Route
              path="/powerup2"
              element={
                <WithOrientationNotice>
                  <Swipe origin="/levels" isGameWithSound={false} />
                </WithOrientationNotice>
              }
            />
            <Route path="/success1" element={<Game origin="/powerup" withSound={true} />} />
            <Route path="/success2" element={<Game origin="/powerup" withSound={false} />} />
            <Route path="/nopower" element={<Failure origin="/levels" />} />
          </Routes>
        </WithInvocationCheck>
      </AnimatePresence>
    </div>
  );
};

export default RouterComponent;
