import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { appReducer } from "./slices/app";
import { collectionReducer } from "./slices/collection";
import { swipeReducer } from "./slices/swipe";

export const store = configureStore({
  reducer: {
    app: appReducer,
    swipe: swipeReducer,
    collection: collectionReducer,
    //
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
