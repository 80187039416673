import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectivityStatus } from "@prismadelabs/prismaid";

const initialMessage = "Place card on the screen\nand hold it with your left thumb.";
const firstSwipeMessage = "Swipe slowly with your index finger\nfrom bottom upwards to the dot.";

export enum SwipeType {
  COLLECTION = "Collection",
  GAME = "Game",
}

export type SwipeMessage = {
  title: string;
  message: string;
};

export type SwipeState = {
  connectivityStatus: ConnectivityStatus;
  progress: number;
  isActive: boolean;
  scaleFactor: number;
  swipeMessages: SwipeMessage[];
};

const initialState: SwipeState = {
  connectivityStatus: ConnectivityStatus.ok,
  progress: 0,
  isActive: false,
  scaleFactor: 0,
  swipeMessages: [
    {
      title: "",
      message: "",
    },
  ],
};

export const swipe = createSlice({
  name: "swipe",
  initialState,
  reducers: {
    setConnectivityStatus: (state, action: PayloadAction<ConnectivityStatus>) => {
      state.connectivityStatus = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setScaleFactor: (state, action: PayloadAction<number>) => {
      state.scaleFactor = action.payload;
    },
    requestFirstTouchMessage: (state) => {
      if (state.swipeMessages.length === 1) {
        state.swipeMessages.push({
          title: "Swipe",
          message: firstSwipeMessage,
        });
      }
    },
    addSwipeMessage: (state, action: PayloadAction<SwipeMessage>) => {
      state.swipeMessages.push(action.payload);
    },
    resetSwipeMessage: (state, action: PayloadAction<SwipeType>) => {
      switch (action.payload) {
        case SwipeType.COLLECTION:
          state.swipeMessages = [
            {
              title: "Unlock Troll",
              message: initialMessage,
            },
          ];
          break;
        case SwipeType.GAME:
          state.swipeMessages = [
            {
              title: "Power Up",
              message: initialMessage,
            },
          ];
          break;
        default:
          state.swipeMessages = [
            {
              title: "Apply",
              message: initialMessage,
            },
          ];
          break;
      }
    },
  },
});

export const swipeReducer = swipe.reducer;
export const {
  setConnectivityStatus,
  setProgress,
  setIsActive,
  setScaleFactor,
  requestFirstTouchMessage,
  addSwipeMessage,
  resetSwipeMessage,
} = swipe.actions;
