import { useRef } from "react";
import Sound from "../../Sound";

import click from "../../../assets/sounds/klick.mp3";

export const BrightButton = ({ onClick, ...props }: any) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      className="w-full active:scale-95"
      {...props}
      onClick={() => {
        clickSound.current.play();
        onClick();
      }}
    >
      <div className="p-3 select-none bg-gradient-to-b from-white to-jakala-red rounded-3xl">
        <div className="p-3 text-2xl uppercase font-crispynut rounded-2xl bg-gradient-to-b from-jakala-orange to-jakala-pink">
          {props.title}
        </div>
      </div>
    </button>
  );
};
